<template>
	<v-app class="admin">

		<v-app-bar color="grey darken-3" dark app clipped-left dense flat>
			
			<v-toolbar-title style="cursor: pointer" @click="$router.push('/admin')" >管理画面</v-toolbar-title>
			<v-spacer></v-spacer>
			<!--
			<a class="toolbar-button" href="/admin/settings"  ><b-icon icon="gear-fill" font-scale="1.5"></b-icon></a>
			<a class="toolbar-button" href="/admin/notifications" >通知</a>
			-->
			<v-toolbar-items style="cursor: pointer">
				
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-avatar v-on="on" size="36" style="margin-top: 5px;">
							<img :src="login.image" :alt="login.name" />
						</v-avatar>
					</template>
					<v-list>
						<v-list-item
							link
							@click.stop="$router.push('/home')"
						>
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-home" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="margin-right: 10px">
								<v-list-item-title>ホーム</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item
							v-show="this.$route.path != '/admin'" class="admin"
							link
							@click="$router.push('/admin')"
						>
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account-supervisor" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content style="margin-right: 10px">
								<v-list-item-title>管理画面</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item v-show="this.$route.path != '/mypage'" link @click="$router.push('/mypage')">
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-account" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>アカウント</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-list-item link @click.stop="logoutDialog = true">
							<v-list-item-icon style="margin-right: 10px">
								<v-icon>{{ "mdi-logout" }}</v-icon>
							</v-list-item-icon>
							<v-list-item-content>
								<v-list-item-title>ログアウト</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-menu>
			</v-toolbar-items>
		</v-app-bar>
		<v-main>
			<router-view :key="$route.fullPath" />
		</v-main>
		<v-footer color="grey darken-3" dark app>
			<v-spacer></v-spacer>
			©2023
			<a href="https://www.vegetalia.co.jp/">vegetalia</a>, inc.
		</v-footer>
		<!-- modal -->
		<div class="text-center">
			<v-dialog v-model="logoutDialog" width="500">
				<v-card>
					<v-card-title class="headline"> マイアカウント </v-card-title>
					<v-card-text>ログアウトしますか？</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="logoutDialog = false">
							いいえ
						</v-btn>
						<v-btn color="green darken-1" text @click="logout"> はい </v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		</div>
		<!-- modal END-->
	</v-app>
</template>

<script>

export default {
	components: {
	},
	mounted() {
	},
	computed: {
	},
	data() {
		return {
			login: {
				name: "unknown",
				role: "admin",
				image: "/img/noimage.png"
			},
			logoutDialog: false
		};
	},
	methods: {
		logout: function() {
			this.$router
				.push({ name: "Login", params: {} })
				.catch(() => {});
			this.logoutDialog = false;
		}
	}
};
</script>
<style lang="scss">

.admin { 
    main{ 
		background-color: #E8E8E8;
		.v-main__wrap {
			padding: 1em ; 
			.header { display:flex; justify-content: space-between; max-width: 1000px !important; margin : 2em auto 2em !important; 
				h1 { font-size: 22px; font-weight: bold; }
				.search-box { display:flex; justify-content: flex-end; min-width: 300px; 
					button { margin-left: .5em; }
				}
			}
			.body {
				max-width: 1000px; margin: 0 auto;
			}
			.home {
				table {
					
					a { text-decoration: none; display: flex; justify-content: space-between; }
				}
			}
		}
	}
    footer {  
        a { text-decoration: none; margin-left: 4px; color: #ffffff ; }
    }
}
.toolbar-button { 
	color: white !important; margin-right: 1em; text-decoration: none ;
}
.place,.settings,.notifications {
	.admin-block { 
		border-top: 1px solid #888; padding: 2em 0;
		h2,h3 { font-size: 20px; font-weight: bold;  }
		label { font-weight: bold; }
		select { background-color: white; padding: .3em 1em; border-radius: 5px; width: 100%; }
		.indent { margin-left: 15%;  }
		.inline { display: inline; }
		.bold { font-weight: bold; }
		.wl-name { text-align: right ; font-weight: bold; }
		.color-block { width: 100%; height: 35px; border-radius: 5px; ;}
		.nested-group {  border-top: 1px solid #888 !important; padding: 1em 0; box-sizing: border-box; 
			button { background-color: transparent; border:none; color: blue; }
		}
		
	}	
	.last-block { 
		text-align: center; margin: 4em 0 ; 
		button:first-child { margin-right: 1em; }
	}
}

</style>